import React from "react";
import { Container } from "components/common";
import {
  Wrapper,
  LogoEvil,
  LinksHeader,
  Details,
  DetailsWrapper,
  Documents,
  LinksWrapper,
  DocumentsMobile,
} from "./styles";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import doc0 from "assets/documents/0.pdf";
import doc1 from "assets/documents/1.pdf";
import doc2 from "assets/documents/2.pdf";
import doc3 from "assets/documents/3.pdf";
import doc4 from "assets/documents/4.pdf";
import doc5 from "assets/documents/5.pdf";
import doc6 from "assets/documents/6.pdf";
import doc7 from "assets/documents/7.pdf";
import doc8 from "assets/documents/8.pdf";
import doc9 from "assets/documents/9.pdf";
import doc10 from "assets/documents/10.pdf";
import doc11 from "assets/documents/Politika_obrabotki_faylov_cookie.doc.pdf";
import doc12 from "assets/documents/Politika_v_otnoshenii_obrabotki_personalnykh_dannykh.doc.pdf";
import evillogo from "assets/illustrations/evillogo.png";

import { Headings } from "../../common";
import { RedLine } from "../..//common";

const useStyles = makeStyles((theme) => ({
  footerMenu: {
    width: "100%",
    display: "flex",
    justifyContent: "left",
  },
  documentsColumn: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    marginBottom: 20,
    fontSize: 12,
  },
  wrapperDocuments: {
    padding: "0 1%",
    flexWrap: "nowrap",
  },
}));

export const Footer = () => {
  const classes = useStyles();
  return (
    <Wrapper>
      <DetailsWrapper>
        <Details>
          <Grid container spacing={2}>
            <Grid item sm={3} md={3} lg={3} className={classes.footerMenu}>
              <AnchorLink href="#movementmap">Как это работает?</AnchorLink>
            </Grid>
            <Grid item sm={3} md={3} lg={3} className={classes.footerMenu}>
              <AnchorLink href="#equipment">Что входит в комплект?</AnchorLink>
            </Grid>
            <Grid item sm={3} md={3} lg={3} className={classes.footerMenu}>
              <AnchorLink href="#calculator">Калькулятор</AnchorLink>
            </Grid>
            <Grid item sm={3} md={3} lg={3} className={classes.footerMenu}>
              <AnchorLink href="#FAQ">FAQ</AnchorLink>
            </Grid>
          </Grid>
          {/* <AnchorLink href="#movementmap" >Как это работает?</AnchorLink>
      <AnchorLink href="#equipment">Что входит в комплект?</AnchorLink>
      <AnchorLink href="#calculator">Калькулятор</AnchorLink>
      <AnchorLink href="#FAQ">FAQ</AnchorLink> */}
        </Details>
      </DetailsWrapper>

      <Documents>
        <LinksWrapper>
          <Grid container spacing={2} className={classes.wrapperDocuments}>
            <Grid item sm={3} md={4} lg={3} className={classes.documentsColumn}>
              <a href={doc0} target="_blank" className={classes.text}>
                Сертификат о <b>взрывозащите</b>
              </a>
              <a href={doc7} target="_blank" className={classes.text}>
                Декларация о соответствии <b>беспроводного программатора</b>
              </a>
              <a href={doc5} target="_blank" className={classes.text}>
                Декларация о соответствии{" "}
                <b>считывающих устройств модели Nozzle Reader</b>
              </a>
            </Grid>

            <Grid item sm={3} md={4} lg={3} className={classes.documentsColumn}>
              <a href={doc2} target="_blank" className={classes.text}>
                Декларация о соответствии <b>средств электросвязи 1</b>
              </a>
              <a href={doc3} target="_blank" className={classes.text}>
                Декларация о соответствии <b>средств электросвязи 2</b>
              </a>
              <a href={doc6} target="_blank" className={classes.text}>
                Декларация о соответствии <b>средств электросвязи 3</b>
              </a>
            </Grid>

            <Grid item sm={3} md={4} lg={3} className={classes.documentsColumn}>
              <a href={doc4} target="_blank" className={classes.text}>
                Декларация о соответствии <b>платёжных терминалов 1</b>
              </a>
              <a href={doc10} target="_blank" className={classes.text}>
                Декларация о соответствии <b>платёжных терминалов 2</b>
              </a>
              <a href={doc8} target="_blank" className={classes.text}>
                Декларация о соответствии{" "}
                <b>устройств для сбора информации о транспортном средстве</b>
              </a>
            </Grid>

            <Grid item sm={3} md={4} lg={3} className={classes.documentsColumn}>
              <a href={doc9} target="_blank" className={classes.text}>
                Декларация о соответствии <b>беспроводных терминалов</b>
              </a>
              <a href={doc1} target="_blank" className={classes.text}>
                Декларация о соответствии{" "}
                <b> устройств по сбору и передаче информации</b>
              </a>
              {/* <a href={doc12} target="_blank" className={classes.text}>
                Политика обработки <b>персональных данных</b>
              </a>
              <a href={doc11} target="_blank" className={classes.text}>
                Политика обработки <b>файлов cookies</b>
              </a> */}
            </Grid>
          </Grid>
        </LinksWrapper>
        <LogoEvil>
          <a
            href="https://evilmarketing.ru/?utm_source=tank"
            target="_blank"
            rel="noreferrer"
          >
            Made By evilUnion
            {/* <img
              width="100"
              height="44"
              src={evillogo}
              alt="Логотип компании EvilMarketing"
            ></img> */}
          </a>
        </LogoEvil>
      </Documents>

      <DocumentsMobile>
        {/* <a
          href={doc12}
          target="_blank"
          className={classes.text}
          style={{ marginRight: "30px" }}
        >
          Политика обработки <b>персональных данных</b>
        </a>
        <a href={doc11} target="_blank" className={classes.text}>
          Политика обработки <b>файлов cookies</b>
        </a> */}
        <LogoEvil>
          <a
            href="https://evilmarketing.ru/?utm_source=tank"
            target="_blank"
            rel="noreferrer"
          >
            Made By evilUnion
            {/* <img
              width="100"
              height="44"
              src={evillogo}
              alt="Логотип компании EvilMarketing"
            ></img> */}
          </a>
        </LogoEvil>
      </DocumentsMobile>
    </Wrapper>
  );
};
