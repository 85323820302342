import styled from 'styled-components';

export const Button = styled.div`
  display: flex;
  align-items: center;
  max-width: fit-content;
   font-family: 'Montserrat'

  cursor: pointer;
  border-radius: 3px;
  padding: 0.3rem 0.5rem;
  border: 3px solid #DE7D76;
  box-sizing: border-box;
  border-radius: 44px;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000;
  font-weight: 700;
  background: #ffffff;
  
  p {
    margin: 0 !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: gray;
  }

  ${({ secondary }) =>
    secondary &&
    `
    background: #ffffff;
	`}
`;
