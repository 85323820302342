import React from "react";
import { makeStyles } from "@material-ui/core";

import Cross from "../../../assets/svg_plugin/cross.svg";

const useStyles = makeStyles({
  modal: {
    height: "100vh",
    width: "100vw",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 999,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    position: "relative",

    padding: 20,
    backgroundColor: "#fff",

    borderRadius: "1.56vw",
    "@media(min-width: 1280px)": {
      borderRadius: "30px",
    },
    "@media(max-width: 767px)": {
      borderRadius: "3vw",
    },
  },
  cross: {
    position: "absolute",

    background: "transparent",
    border: "none",
    cursor: "pointer",

    top: 20,
    right: 20,

    width: 20,
    height: 20,
  },
});

export default function ModalWindow({ active, setActive, info }) {
  const classes = useStyles();

  const visible = (active) => {
    switch (active) {
      case true:
        return (
          <div className={classes.modal} onClick={() => setActive(false)}>
            <div
              className={classes.modalContent}
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={() => setActive(false)}
                className={classes.cross}
              >
                <Cross />
              </button>

              {info}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return visible(active);
}
