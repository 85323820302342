import React, { useContext } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ToggleTheme from "components/theme/Header/ToggleTheme";
import { Wrapper, Brand, LinksHeader } from "./styles";
import { ButtonRed } from "components/common";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";

import doc0 from "assets/documents/0.pdf";

const useStyles = makeStyles((theme) => ({
  button: {
    opacity: 0,
    "@media(max-width: 960px)": {
      opacity: 1,
      padding: "16px 0",
    },
  },
  text: {
    fontSize: "1.28vw",
    "@media(min-width: 1280px)": {
      fontSize: 18,
    },
    "@media(max-width: 960px)": {
      fontSize: "1.87vw",
    },
    "@media(min-width: 960px)": {
      "&:hover": {
        color: " #C51B10",
        transform: " scale(1.1)",
      },
    },
  },
  text_button: {
    fontSize: "1vw",
    "@media(min-width: 1280px)": {
      fontSize: 13,
    },
    "@media(max-width: 960px)": {
      fontSize: "1.87vw",
    },
    "@media(max-width: 720px)": {
      fontSize: "3vw",
    },
    "@media(max-width: 500px)": {
      fontSize: "3.87vw",
    },
  },
}));

const NavbarLinks = ({ desktop }) => {
  const classes = useStyles();

  return (
    <Wrapper desktop={desktop}>
      <Brand as={Link} to="/">
        <img
          width="220"
          height="50"
          src="svg/logo4.svg"
          alt="Логотип Автотанк"
        ></img>
      </Brand>

      <LinksHeader>
        <AnchorLink href="#movementmap" className={classes.text}>
          Как это работает
        </AnchorLink>
        <AnchorLink href="#equipment" className={classes.text}>
          Что входит в комплект
        </AnchorLink>
        <AnchorLink href="#calculator" className={classes.text}>
          Калькулятор
        </AnchorLink>
        <a href={doc0} target="_blank" className={classes.text}>
          Сертификация
        </a>
      </LinksHeader>

      {/* <ToggleTheme /> */}
      <ButtonRed
        as={AnchorLink}
        href="#contact"
        className={classes.text_button + " " + classes.button}
      >
        Оставить заявку
      </ButtonRed>
    </Wrapper>
  );
};

export default NavbarLinks;
