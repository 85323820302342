import React, { useContext } from "react";
import { Footer } from "components/theme";
import { Global } from "./styles";
import "./fonts.css";

import { ButtonRed } from "components/common";
import { makeStyles } from "@material-ui/core/styles";
import AnchorLink from "react-anchor-link-smooth-scroll";

import ModalWindow from "../../../components/landing/ModalWindow";
import DownloadForm from "../../../components/landing/DownloadForm";

const useStyles = makeStyles((theme) => ({
  panelButton: {
    width: "100%",
    height: 102,
    maxWidth: "1280px",
    margin: "0 auto",
    pointerEvents: "none",

    position: "fixed",
    left: 0,
    right: 0,

    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    zIndex: 100,

    "@media(max-width: 960px)": {
      bottom: 20,
      height: "auto",

      justifyContent: "center",
    },
  },
  panelButton_2: {
    width: "100%",
    maxWidth: "1280px",
    margin: "0 auto",
    pointerEvents: "none",

    position: "fixed",
    left: 0,
    right: 0,
    top: 75,
    zIndex: 100,

    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    "@media(max-width: 960px)": {
      top: "auto",
      bottom: 70,
      height: "auto",

      justifyContent: "center",
    },
    "@media(max-width: 500px)": {
      marginBottom: "10px",
    },
    "@media(max-width: 720px)": {
      marginBottom: "2vw",
    },
  },
  text: {
    pointerEvents: "auto",
    marginRight: 0,
    border: "none",

    fontSize: "1vw",
    "@media(min-width: 1280px)": {
      fontSize: 13,
    },
    "@media(max-width: 960px)": {
      fontSize: "1.87vw",
    },
    "@media(max-width: 720px)": {
      fontSize: "3vw",
    },
    "@media(max-width: 500px)": {
      fontSize: "3.87vw",
    },
    "@media(min-width: 960px)": {
      marginRight: "0.78vw",

      "&:hover": {
        color: " #C51B10",
        transform: " scale(1.1)",
      },
    },

    // "&:hover": {
    //   color: " #C51B10",
    //   transform: "none",
    // },
  },
}));

export const Layout = ({ children }) => {
  const classes = useStyles();

  const [modalActive, setModalActive] = React.useState(false);

  const [showButton, setShowButton] = React.useState(false);
  const [mobile, setMobile] = React.useState(false);

  React.useEffect(() => {
    setMobile(window.screen.width <= 960);
  }, []);

  React.useEffect(() => {
    const contact = document.querySelector("#contact");
    const presentation = document.querySelector("#presentation");

    function changeShow() {
      const formRect = contact.getBoundingClientRect();
      const aboveForm = formRect.top <= 0 && formRect.top >= -formRect.height;

      const presentationRect = presentation.getBoundingClientRect();
      const abovePresentation =
        presentationRect.top <= 100 &&
        presentationRect.top >= -presentationRect.height &&
        !mobile;

      setShowButton(!aboveForm && !abovePresentation);
    }
    changeShow();

    document.addEventListener("scroll", changeShow);
    return () => document.removeEventListener("scroll", changeShow);
  }, []);

  return (
    <>
      <div
        className={classes.panelButton}
        style={{ opacity: showButton ? 1 : 0 }}
      >
        <ButtonRed as={AnchorLink} href="#contact" className={classes.text}>
          Оставить заявку
        </ButtonRed>
      </div>

      <div
        className={classes.panelButton_2}
        style={{ opacity: showButton ? 1 : 0 }}
      >
        <ButtonRed
          onClick={() => {
            setModalActive(true);
          }}
          className={classes.text}
        >
          Скачать презентацию
        </ButtonRed>
      </div>
      <Global />
      {children}
      <Footer />

      <ModalWindow
        active={modalActive}
        setActive={setModalActive}
        info={<DownloadForm close={() => setModalActive(false)} />}
      />
    </>
  );
};
