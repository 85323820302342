import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 960px) {
    padding: 1.5rem 0;
  }
  @media (max-width: 960px) {
    padding: 1.5rem 0;
  }
`;

export const Brand = styled.a`
  @media (min-width: 961px) {
    display: none;

  }

  @media (max-width: 960px) {
    margin-left: 3vw

  }
`;
