import styled from 'styled-components';

export const Headings = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    line-height: 150%;
    color: #222222;
    margin-bottom: 40px;

  @media (max-width: 993px) {
    padding-left: 1rem;
    width: 90%;
    font-size: 38px;
    margin-bottom: 20px;
  }
`;

export const Headings2 = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    color: #222222;
    margin-bottom: 10px;

    @media (max-width: 993px) {
      font-size: 18px;
      font-width: 500;
    }

  @media (min-width: 994px) {
    width: 100%;
  }
`;

export const HeadingsRed = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    color: #C51B10,
    @media (max-width: 960px) {
      color: #C51B10 !important,
    }
`;

export const NumberRed = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    color: #DE7D76;
    font-size: 80px;
    font-weight: 700;
`;

export const TextRed = styled.span`
    color: #DE7D76
`;

export const TextBlue = styled.span`
    color: #00559B
`;

export const TextGreen = styled.span`
    color: #008C3D
`;
