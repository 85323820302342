import footerIllustration from "assets/illustrations/footer.svg";
import styled from "styled-components";
import mapfooter from "assets/illustrations/mapfooter.png";

export const Wrapper = styled.div`
  background-color: #f4f4f4;
  padding: 0rem 0 0rem 0;
  // background-color: #C51B10;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  a {
    color: #000;
    text-decoration: none;
    font-weight: 700;
    font-family: "Montserrat";
    &:hover {
      color: black;
      transform: scale(1.1);
    }
  }
`;

export const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c51b10;
`;

export const Details = styled.div`
  padding: 2rem 1rem 2rem 1rem;
  background-color: #c51b10;
  width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2,
  a,
  span {
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 680px) {
    flex-direction: column;
  }
`;

export const Documents = styled.div`
  background: url(${mapfooter});
  background-size: auto 110%;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h2,
  a,
  span {
    color: #c51b10;
    text-decoration: none;
    font-weight: 400;
  }

  @media (max-width: 770px) {
    display: none;
    margin-bottom: 2rem;
    flex-direction: column;
  }
`;

export const DocumentsMobile = styled.div`
display: none;
@media (max-width: 770px) {
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 16px;
  display: flex;
  width: 100%,
  justifyContent:left
  flex-direction: column;
  flex-wrap: wrap;

  a {
    font-weight: 400;
  }
}
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2,
  a,
  span {
    color: #c51b10;
    text-decoration: none;
    font-weight: 400;
  }

  @media (max-width: 680px) {
    margin-bottom: 2rem;
    flex-direction: column;
  }
`;

export const LogoEvil = styled.div`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: 770px) {
    justify-content: left;
    margin-top: 20px;
    align-items: start;
  }
`;
