import styled from 'styled-components';

export const ButtonMore = styled.span`
  margin-top: 15px;
  display: flex;
  align-items: center;
  max-width: fit-content;
  font-weight: 600;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;

  border-bottom: 2px #DE7D76 solid;

  &:focus {
    outline: none;
  }

  &:disabled {
    background: gray;
  }

  ${({ secondary }) =>
    secondary &&
    `
    background: #ffffff;
	`}
`;
