import styled from "styled-components";

export const Wrapper = styled.div`
  a {
    color: #FFFF;
    text-decoration: none;
    font-weight: 700;
    font-family: "Montserrat";

    @media (max-width: 960px) {
      color: ${({ theme }) => (theme === "light" ? "#000" : "#fff")};
    }
  }

  ${({ desktop }) =>
    desktop
      ? `
			align-items: center;
			display: flex;
			justify-content: space-between;
			width: 100%;

			@media (max-width: 960px) {
					display: none;
			}

			a {
					margin-right: 1rem;

					&:last-child {
							margin-right: unset;
					}
			}
		`
      : `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
			}
	`}
`;

export const Brand = styled.a`
  color: ${({ theme }) => (theme === "light" ? "#000" : "#fff")};

  @media (max-width: 960px) {
    display: none;
  }
`;

export const LinksHeader = styled.div`
  display: flex;
  @media (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 20px;

    a {
      color: #000;
    }
  }

  a {
    flex-shrink: 0;
    white-space: nowrap;
  }
`;
