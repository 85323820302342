import React from "react";

import { makeStyles, Modal, Grid, Typography } from "@material-ui/core";
import { ButtonRed } from "components/common";

const useStyles = makeStyles((theme) => ({
  form: {
    margin: "75px auto 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    width: "58.54vw",
    "@media(min-width: 1280px)": {
      width: "750px",
    },
    "@media(max-width: 960px)": {
      width: "90%",
    },

    "& label": {
      width: "100%",
      flexShrink: 0,
      display: "block",
      marginTop: "20px",
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: 1.5,
      color: "#222222",
      "& b": {
        color: "#DE7D76",
      },
      "&:first-child": {
        marginTop: 0,
      },
    },

    "& input": {
      boxSizing: "border-box",
      border: `3px solid #E5E5E5`,
      background: "transparent",
      outline: "none",

      "&:-webkit-autofill": {
        "-webkit-text-fill-color": "#000",
        "-webkit-box-shadow": `0 0 0 30px #fff inset !important`,
        caretColor: "#000",
      },

      marginTop: "8px",
      width: "100%",
      height: 65,
      fontSize: "20px",
      lineHeight: 1.5,
      padding: "17.5px 10px",

      "&::placeholder": {
        fontWeight: "normal",
        color: "#828282",
      },
    },

    "& input.important": {
      borderColor: "#F6D7DA",
    },

    "& textarea": {
      boxSizing: "border-box",
      border: `3px solid #E5E5E5`,
      background: "transparent",
      outline: "none",

      "&:-webkit-autofill": {
        "-webkit-text-fill-color": theme.palette.primary.white,
        "-webkit-box-shadow": `0 0 0 30px ${theme.palette.background.main} inset !important`,
        caretColor: theme.palette.primary.white,
      },

      marginTop: "8px",
      width: "100%",
      height: 251,
      fontSize: "20px",
      lineHeight: 1.5,
      padding: "15px 10px",

      "&::placeholder": {
        fontWeight: "normal",
        color: "#828282",
      },
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
  },
  modalContent: {
    width: "30vw",
    margin: "auto",
    padding: "1.56vw",
    outline: "none",
    background: "#fff",
    borderRadius: "1.56vw",
    "@media(min-width: 1280px)": {
      width: "384px",
      padding: "30px",
      borderRadius: "30px",
    },
    "@media(max-width: 767px)": {
      width: "82vw",
      padding: "3vw",
      borderRadius: "3vw",
    },
  },
  modalContentText: {
    fontSize: "1.87vw",
    "@media(min-width: 1280px)": {
      fontSize: "24px",
    },
    "@media(max-width: 767px)": {
      fontSize: "5.79vw",
    },
  },
  error: {
    width: "100%",
    marginRight: "0.8em",
    color: "red",

    fontSize: "1.4vw",
    "@media(min-width: 1280px)": {
      fontSize: "18px",
    },
    "@media(max-width: 767px)": {
      fontSize: "4.34vw",
    },
  },
  buttonRed: {
    marginTop: 75,
    borderColor: "transparent",

    "@media(min-width: 960px)": {
      "&:hover": {
        color: " #C51B10",
        transform: " scale(1.1)",
      },
    },
  },
  errorMessage: {
    width: "100%",
    marginTop: "1em",

    fontSize: "1.4vw",
    "@media(min-width: 1280px)": {
      fontSize: "18px",
    },
    "@media(max-width: 767px)": {
      fontSize: "4.34vw",
    },
  },
  sendMessage: {
    fontWeight: 700,

    fontSize: "2.81vw",
    "@media(min-width: 1280px)": {
      fontSize: "36px",
    },
    "@media(max-width: 767px)": {
      fontSize: "5.79vw",
    },
  },
}));

export default function DownloadForm({ close }) {
  const classes = useStyles();

  const [errors, setErrors] = React.useState(false);
  const [sendData, setSendData] = React.useState(false);

  function setBorderColor(elem, flag) {
    if (flag) elem.style.borderColor = "#F6D7DA";
    else elem.style.borderColor = "#C51B10";
  }

  function validationPhone(e) {
    setBorderColor(
      e.target,
      /^\s*(\+?[78]-?\(?\d{3}\)?-?)?\d{3}-?\d{2}-?\d{2}\s*$/.test(
        e.target.value.replace(/\s/g, "")
      )
    );
  }

  function validationEmail(e) {
    setBorderColor(e.target, /@/.test(e.target.value));
  }

  function validationCompanyName(e) {
    setBorderColor(e.target, e.target.value !== "");
  }

  function downloadPresentation() {
    const anchor = document.createElement("a");
    anchor.href = "presentation.pdf";
    anchor.target = "_blank";
    anchor.click();
  }

  function submit(e) {
    e.preventDefault();
    const form = e.nativeEvent.target;
    const email = form.email.value;
    const phone = form.phone.value;
    const company_name = form.company_name.value;

    const errorsArr = [];
    if (!/@/.test(email))
      errorsArr.push(
        <Typography className={classes.error} align="left">
          - некорректный e-mail
        </Typography>
      );
    if (
      !/^\s*(\+?[78]-?\(?\d{3}\)?-?)?\d{3}-?\d{2}-?\d{2}\s*$/.test(
        phone.replace(/\s/g, "")
      )
    )
      errorsArr.push(
        <Typography className={classes.error} align="left">
          - некорректный номер телефона
        </Typography>
      );
    if (company_name === "")
      errorsArr.push(
        <Typography className={classes.error} align="left">
          - введите название компании
        </Typography>
      );
    if (errorsArr.length) {
      setErrors(errorsArr);
      setTimeout(setErrors, 5000, false);
    } else {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      const body = JSON.stringify({
        email,
        phone,
        company_name,
      });
      const init = {
        method: "POST",
        headers,
        body,
      };

      const url = new URL(window.location);

      fetch(`${url.origin}/.netlify/functions/email_server`, init).then(
        (res) => {
          console.log(res);
          if (res.status === 200 || res.status === 502) {
            setSendData(true);
            form.reset();
            downloadPresentation();
          } else {
            const message = (
              <Typography className={classes.errorMessage} align="center">
                Что-то пошло не так, сообщите нам о проблеме{" "}
                <a
                  href="mailto:sales-cis@gilbarco.com"
                  style={{ color: "#C51B10" }}
                >
                  sales-cis@gilbarco.com
                </a>
              </Typography>
            );
            setErrors(message);
          }

          setTimeout(close, 2000);
        }
      );
    }
  }

  return (
    <>
      <form onSubmit={submit} className={classes.form}>
        <label>
          Номер телефона<b>*</b>
          <input
            placeholder="Номер телефона"
            name="phone"
            onInput={validationPhone}
            className="important"
          />
        </label>

        <label>
          Адрес электронной почты<b>*</b>
          <input
            placeholder="Адрес электронной почты"
            name="email"
            onInput={validationEmail}
            className="important"
          />
        </label>

        <label>
          Название компании<b>*</b>
          <input
            placeholder="Название компании"
            name="company_name"
            onInput={validationCompanyName}
            className="important"
          />
        </label>

        <ButtonRed className={classes.buttonRed}>Скачать</ButtonRed>
      </form>

      {errors ? (
        <Modal
          open={errors}
          onClose={() => setErrors(false)}
          className={classes.modal}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.modalContent}
          >
            <Typography align="center" className={classes.modalContentText}>
              Данные не отправлены
            </Typography>
            {errors}
          </Grid>
        </Modal>
      ) : null}

      {sendData ? (
        <Modal
          open={sendData}
          onClose={() => setSendData(false)}
          className={classes.modal}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.modalContent}
          >
            <Typography align="center" className={classes.sendMessage}>
              Данные успешно отправлены
            </Typography>
          </Grid>
        </Modal>
      ) : null}
    </>
  );
}
