import styled from 'styled-components';

export const RedLine = styled.div`
    border-top: 9px solid #C51B10;
    max-width: 80px;

  @media (min-width: 601px) {
    width: 90%;
  }

  @media (min-width: 993px) {
    width: 90%;
  }
`;
