import styled from "styled-components";

export const ButtonRed = styled.button`
  cursor: pointer;
  border-radius: 3px;
  padding: 0.7em 2.5em;
  border-radius: 44px;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  background: #c51b10;
  color: #ffffff !important;
  font-family: "Montserrat";

  &:focus {
    outline: none;
  }

  &:disabled {
    background: gray;
  }

  a {
    color: #ffffff;
  }

  ${({ secondary }) =>
    secondary &&
    `
    background: #ffffff;
	`}
`;
